import React, { useState } from "react";
import {
  Table,
  Checkbox,
  Button,
  Row,
  Col
} from "antd";
import { useQuery } from "@apollo/client";
import useI18n from "../../../utils/useI18n";
import { GET_PAGINATED_ENVIRONMENTS_QUERY } from "../../../utils/hooks/useEnvironment";
import styles from "./index.module.sass";
import columns from "./columns";
import AntForm from "@9troisquarts/ant-form";
import { EnvironmentListData as EnvironmentListProps } from "../../../interfaces/environment";
import { UserRole } from "../../../utils/user";
import { InlineFilters, useSearchFilters } from "@9troisquarts/wand";
import { history } from "../../../store";
import { SearchOutlined } from "@ant-design/icons";

const environmentSearchDefinition = {
  search: "String",
  onlyAccessible: "Boolean",
};

const EnvironmentList: React.FC<EnvironmentListProps> = props => {
  const {
    onChange: onSearchChange,
    onPageChange,
    search,
    page,
    perPage,
  } = useSearchFilters('environments', {
    updateLocation: true,
    history: history,
    definition: environmentSearchDefinition,
    defaultSearch: {
      onlyAccessible: true,
    },
  });

  const { data, loading } = useQuery(GET_PAGINATED_ENVIRONMENTS_QUERY, {
    variables: {
      search: {
        searchICont: search.search
      },
      page: page,
      perPage: perPage || 50,
      onlyAccessible: search.onlyAccessible,
    }
  })
  const {
    pagination,
    environments
  } = data?.environments || {};
  const {
    user,
    onEdit
  } = props;
  const { t } = useI18n();

  return (
    <div
      style={{ marginTop: 10 }}
    >
      <Row>
        <Col span={16} style={{ marginBottom: '1rem' }}>
          <InlineFilters
            value={search}
            schema={[
              {
                name: "search",
                icon: <SearchOutlined />,
                title: 'Recherche',
                style: {
                  width: 300,
                },
                input: {
                  type: "string",
                  inputProps: {
                    placeholder: "Rechercher..."
                  }
                }
              },
              {
                name: "onlyAccessible",
                label: t('page.environment.only_mine'),
                input: {
                  type: "boolean",
                }
              }
            ]}
            onChange={onSearchChange}
            onReset={() => onSearchChange({})}
            resetText="Réinitialiser"
          />
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          { user.role == UserRole.SuperAdministrator && (
            <div className="new-portal-button">
              <Button
                type="primary"
                onClick={() => onEdit(undefined)}
                >
                  {t('page.environment.new')}
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <Table
        loading={loading}
        columns={columns(user, t, onEdit)}
        bordered={false}
        dataSource={environments}
        onChange={(pagination) => onPageChange(pagination.current, pagination.pageSize)}
        pagination={{
          pageSize: pagination?.perPage,
          total: pagination?.total,
          showSizeChanger: true,
          current: pagination?.page,
        }}
      />
    </div>
  )
}

export default EnvironmentList;