import React, { useState } from "react";
import { connect } from 'react-redux';
import { useQuery } from "@apollo/client";
import UserEdit from "./UserEdit"
import UserList from "./UserList";
import useUser, { GET_USER } from "../../utils/hooks/useUser";
import { UserType } from "../../types";
import useAccess from "../../utils/hooks/useAccess";
import AccessDenied from "../../components/AccessDenied";

interface EnvironmentProps {
  user: any;
}

const User: React.FC<EnvironmentProps> = props => {
  const {
    user,
  } = props;

  const [editOpen, setEditOpen] = useState(false)
	const [editingUserID, setEditingUserID] = useState(undefined)

  const {
    data: editingUserData,
    loading: editingUserLoading,
  } = useQuery(GET_USER, {
    variables: {
      id: editingUserID,
    },
    skip: !editingUserID,
    fetchPolicy: 'network-only',
  })

  const editingUser: UserType = editingUserData?.user

  const {
    saveUser,
    deactivateUser,
    destroyUser,
    errors,
  } = useUser(editingUser?.id, {
    onCompleted: (data: any) => {
      const { recordErrors, updateUser, createUser, deactivateUser } = data;
      if(createUser?.user || updateUser?.user || deactivateUser?.user) {
        setEditingUserID(undefined);
        setEditOpen(false);
      }      
    },
    refetchQueries: [
      'users'
    ]
  })

	const onEdit = (id) => {
		setEditOpen(true);
		setEditingUserID(id)
	}

  const { canReadUsers } = useAccess();

  if (!canReadUsers) {
    return (
      <AccessDenied />
    )
  }

  const onCancel = () => {
    setEditOpen(false)
    setEditingUserID(null)
  };

  return (
    <>
      {editOpen && (
        <UserEdit
          currentUser={user}
          onCancel={onCancel}
          onDeactivate={deactivateUser}
          onSave={saveUser}
          editingUser={{ ...editingUser, environments: editingUser?.environments?.map((env) => ({ label: env.title, value: env.id })) }}
          open={editOpen}
          onDestroy={destroyUser}
          loading={editingUserLoading}
          errors={errors}
        />
      )}
      <UserList
        user={user}
        onEdit={onEdit}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.current
})

export default connect(mapStateToProps, null)(User);