// @ts-nocheck
import React from 'react';
import { createRoot } from 'react-dom/client';
import Rails from "@rails/ujs";
import 'antd/dist/antd.less';
import '../assets/stylesheets/global.sass';
import App from '../react/App';

// This is how react_on_rails can see the HelloWorld in the browser.
if(!window._rails_loaded) Rails.start();

const container = document.getElementById('root');
const root = createRoot(container!);
const props = JSON.parse(container?.dataset.props || '{}')

root.render(
  <React.StrictMode>
    <App {...props} />
  </React.StrictMode>
);