//@ts-nocheck
import "../../../node_modules/@9troisquarts/inline-filters/dist/style.css"
import React from "react";
import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import { NtqToolbarProvider, NtqToolbar } from "@9troisquarts/wand";
import { Route, Switch } from "react-router-dom";
import AntForm, {
  AntFormReactSelect,
  AntFormRailsNested,
  AntFormColorPicker,
} from '@9troisquarts/ant-form';import fr from 'antd/lib/locale/fr_FR';
import { Provider } from "react-redux"
import { UserType } from "./types";
import store, { history } from './store';
import { ApolloProvider } from "@apollo/client";
import graphqlClient from './utils/graphqlClient';
import routes from './routes';
import Layout from "./Layout";
import Environment from "./pages/Environment";
import AccessDenied from "./components/AccessDenied";
import { AlertProvider } from '../react/utils/ContextProvider/AlertContext';

const isDev = process.env.NODE_ENV === "development";

type IProps = {
  user: UserType;
}

AntForm.configure({
  formProps: {
    layout: "vertical",
  }
});

AntForm.addField('reactSelect', {
  component: AntFormReactSelect,
  loadingMessage: () => 'Chargement',
  noOptionsMessage: () => 'Pas de résultat',
  isClearable: true,
});

AntForm.addField('color', {
  component: AntFormColorPicker,
});

AntForm.addField('railsNestedList', {
  component: AntFormRailsNested,
  showFormItemError: false,
});


const { client } = graphqlClient({ url: '/graphql' });

const App: React.FC<IProps> = props => {
  return (
    <ConfigProvider locale={fr}>
      <Provider store={store(props)}>
        <NtqToolbarProvider enabled={false}>
          <ConnectedRouter history={history}>
            <ApolloProvider client={client}>
              <AlertProvider>
                <Layout>
                  <Switch>
                    <Route key={"home"} exact path={"/"} component={Environment} />
                    <Route key={"access-denied"} exact path={"/access-denied"} component={AccessDenied} />
                    {routes.map(route => (
                      <Route key={route.name} exact path={route.path} component={route.component} />
                    ))}
                  </Switch>
                </Layout>
              </AlertProvider>
            </ApolloProvider>
          </ConnectedRouter>
        </NtqToolbarProvider>
      </Provider>
    </ConfigProvider>
  )
}

export default App;