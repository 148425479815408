import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Modal, PageHeader, Space, Table, Tag } from 'antd';
import React from 'react';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { SettingOutlined, SyncOutlined, ToolOutlined } from '@ant-design/icons';
import { UserRole } from '../../utils/user';

const GET_CX_APPLICATIONS = gql`
  query cxApplications($page: Int) {
    cxApplications(page: $page) {
      cxApplications {
        id
        title
        url
        serverAppName
        slug
        streams {
          id
          title
          integrityReport {
            id
            state
            hasError
            indexedAnswersCount
            answersCount
            answersOrphanCount
            answersDuplicateCount
          }
        }
      }
      pagination {
        totalPage
        page
        total
        perPage
      }
    }
  }
`;


const SYNCHRONIZE_CX_APPLICATIONS = gql`
  mutation syncCxApplications($input: SyncCxApplicationsInput!) {
    syncCxApplications(input: $input) {
      success
    }
  }
`;

const CXFirst = () => {
  const [page, setPage] = React.useState(1);
  const [currentCxApplication, setCurrentCxApplication] = React.useState(undefined);
  const {
    data,
    loading
  } = useQuery(GET_CX_APPLICATIONS, {
    variables: {
      page
    }
  });

  const [syncAllCxApplications, { loading: syncing }] = useMutation(SYNCHRONIZE_CX_APPLICATIONS, { variables: { input: {} } });
  const user = useCurrentUser();
  console.log('user: ', user)
  
  const onSync = () => syncAllCxApplications();

  const onFix = (cxApplication) => setCurrentCxApplication(cxApplication);
  const onFixClose = () => setCurrentCxApplication(undefined);

  const columns = [
    {
      dataIndex: 'title',
      title: "", 
      render: (title, { url, serverAppName }) => (
        <>
          <div>
            <a href={url} target="_blank">
              {title}
            </a>
          </div>
          <div>
            <Tag color="blue">
              {serverAppName}
            </Tag>
          </div>
        </>
      )
    },
    {
      key: 'streamCount',
      align: 'right',
      title: "Nombre de flux",
      sorter: (a, b) => a.streams.length - b.streams.length,
      render: ({ streams }) => streams.length
    },
    {
      key: 'integrity',
      align: 'right',
      title: "Flux en erreur",
      sorter: (a, b) => {
        const streamWithIntegrityReportA = a.streams.filter(stream => stream.integrityReport).filter(stream => stream.integrityReport.hasError);
        const streamWithIntegrityReportB = b.streams.filter(stream => stream.integrityReport).filter(stream => stream.integrityReport.hasError);
        return streamWithIntegrityReportB.length - streamWithIntegrityReportA.length;
      },
      render: ({ streams }) => {
        const streamWithIntegrityReport = streams.filter(stream => stream.integrityReport);
        if (streamWithIntegrityReport.length === 0) {
          return '-';
        }
        const errorCount = streamWithIntegrityReport.filter(stream => stream.integrityReport?.hasError).length;
        return (
          <div>
            <span className="text-error">{errorCount}</span>
          </div>
        )
      }
    },
    {
      title: "",
      align: 'right',
      render: (cxApplication) => (
        <div>
          {user.role === UserRole.SuperAdministrator && (
            <Button
              icon={<ToolOutlined />}
              onClick={() => onFix(cxApplication)}
              type="text"
            />
          )}
        </div>
      )
    }
  ];

  return (
    <>
      {currentCxApplication && (
        <Modal
          onCancel={onFixClose}
          open
          width={700}
          footer={false}
        >
          <p>
            scalingo -a {currentCxApplication.serverAppName} run bundle exec rails console
          </p>
          <p>
            Tenant.switch_to_database "{currentCxApplication.slug}"
            <br />
            Stream.all.each do |stream|
              <br />
              &nbsp;&nbsp;next unless stream.integrity_report
              <br />
              &nbsp;&nbsp;StreamIntegrity::ProcessReportCleaning.call(integrity_report: stream.integrity_report)
              <br />
            end
          </p>
        </Modal>
      )}
      <PageHeader
        title="Portails CX"
        extra={[
          (user.role === UserRole.SuperAdministrator) && (
            <Button loading={syncing} icon={<SyncOutlined />} onClick={onSync}>
              Synchroniser les données
            </Button>
          ),
        ].filter(Boolean)}
      />
      <Table
        dataSource={data?.cxApplications?.cxApplications || []}
        loading={loading}
        columns={columns}
      />
    </>
  )
}

export default CXFirst;