export enum UserRole {
  Normal = 'normal',
  Administrator = 'administrateur',
  SuperAdministrator = 'super-administrateur',
  MegaAdministrator = 'mega-administrateur',
  Developer = 'développeur',
}

export const UserRoleOptions = [
  { value: UserRole.Normal, label: 'Normal' },
  { value: UserRole.Administrator, label: 'Administrateur' },
  { value: UserRole.SuperAdministrator, label: 'Super-administrateur' },
  { value: UserRole.MegaAdministrator, label: 'Mega-administrateur' },
];